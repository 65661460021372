import axios from 'axios'
import { getInstance } from '../auth'

const createClient = async () => {
  const authService = await getInstance()
  const token = await authService.getTokenSilently()

  return axios.create({
    baseURL: process.env.VUE_APP_BOTTIMMO_SERVICE,
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob'
  })
}

export default createClient
